<!--
 * @Date: 2021-07-15 18:01:05
 * @LastEditors: Woz
 * @LastEditTime: 2021-09-24 13:56:06
 * @FilePath: \groundle_mobile\src\views\Scent.vue
-->

<template>
	<div class="scent page">
		<vMenu @beforeLeave="clearPage"></vMenu>
		<div style="overflow: scroll; height: 100%">
			<!-- <div class="title SourceHanSerifSemiBold">SCENT CHART</div>
			<br />
			<div class="voyage">______</div> -->

			<img src="../assets/title_1.png" style="width: 100%" class="title" />

			<div class="icon-list">
				<div class="col">
					<transition
						name="fade"
						v-for="(icon, index) in iconList1"
						v-bind:key="icon.bg"
						v-on:before-enter="beforeEnter"
						v-on:enter="enter"
					>
						<div
							v-bind:list-key="'i1-' + index"
							v-if="loaded"
							class="flip-container icon"
							@click="tap(icon, 'i1-' + index)"
							:class="{ 'hover-p': currentKey == 'i1-' + index }"
						>
							<div class="flipper">
								<div
									class="front voyage icon-title"
									:style="{ background: icon.bg }"
								>
									<!-- 前面内容 -->
									<div
										class="icon-title2"
										v-if="icon.type == 'pic'"
										:style="{ color: icon.color }"
										v-html="icon.innerName"
									>
										<!-- {{ icon.name }} -->
									</div>
									<img
										v-if="icon.type == 'pic'"
										:src="icon.pic"
										class="icon-pic"
									/>
								</div>
								<div class="back voyage" :style="{ background: icon.bg }">
									<div
										v-if="icon.type == 'pic'"
										class="icon-title"
										v-html="icon.innerName"
									>
										<!-- {{ icon.name }} -->
									</div>
								</div>
							</div>
						</div>
					</transition>
				</div>
				<div class="col">
					<transition
						name="fade"
						v-for="(icon, index) in iconList2"
						v-bind:key="icon.bg"
						v-on:before-enter="beforeEnter"
						v-on:enter="enter"
					>
						<div
							v-bind:list-key="'i2-' + index"
							v-if="loaded"
							class="flip-container icon"
							@click="tap(icon, 'i2-' + index)"
							:class="{ 'hover-p': currentKey == 'i2-' + index }"
						>
							<div class="flipper">
								<div
									class="front voyage icon-title"
									:style="{ background: icon.bg }"
								>
									<!-- 前面内容 -->
									<img
										v-if="icon.type == 'pic'"
										:src="icon.pic"
										class="icon-pic"
									/>
									<div
										v-if="icon.type == 'pic'"
										class="icon-title2"
										:style="{ color: icon.color }" v-html="icon.innerName"
									>
										<!-- {{ icon.name }} -->
									</div>
								</div>
								<div class="back voyage" :style="{ background: icon.bg }">
									<div v-if="icon.type == 'pic'" class="icon-title" v-html="icon.innerName">
										<!-- {{ icon.name }} -->
									</div>
								</div>
							</div>
						</div>
					</transition>
				</div>
				<div class="col">
					<transition
						name="fade"
						v-for="(icon, index) in iconList3"
						v-bind:key="icon.bg"
						v-on:before-enter="beforeEnter"
						v-on:enter="enter"
					>
						<div
							v-bind:list-key="'i3-' + index"
							v-if="loaded"
							class="flip-container icon"
							@click="tap(icon, 'i3-' + index)"
							:class="{ 'hover-p': currentKey == 'i3-' + index }"
						>
							<div class="flipper">
								<div
									class="front voyage icon-title"
									:style="{ background: icon.bg }"
								>
									<!-- 前面内容 -->
									<div
										v-if="icon.type == 'pic'"
										class="icon-title2"
										:style="{ color: icon.color }" v-html="icon.innerName"
									>
										<!-- {{ icon.name }} -->
									</div>
									<img
										v-if="icon.type == 'pic'"
										:src="icon.pic"
										class="icon-pic"
									/>
								</div>

								<div class="back voyage" :style="{ background: icon.bg }">
									<div v-if="icon.type == 'pic'" class="icon-title" v-html="icon.innerName">
										<!-- {{ icon.name }} -->
									</div>
								</div>
							</div>
						</div>
					</transition>
				</div>
			</div>

			<!-- <div class="title2 SourceHanSerifSemiBold">LIMITED EDITION</div>
			<br />
			<div class="voyage">______</div> -->

			<img src="../assets/title_2.png" style="width: 100%" class="title2" />
			<transition
				name="fade"
				v-on:before-enter="beforeEnter"
				v-on:enter="enter"
			>
				<div class="big-holder">
					<div
						v-if="loaded"
						class="flip-container icon big-icon big-icon-position"
						@click="tap(icon, 'i0')"
						:class="{ 'hover-p': currentKey == 'i0' }"
					>
						<div class="flipper">
							<div
								class="front voyage icon-title big-icon"
								:style="{ background: icon.bg }"
							>
								<!-- 前面内容 -->
								<div
									v-if="icon.type == 'pic'"
									:style="{ color: icon.color }"
									class="big-icon"
								>
									{{ icon.name }}
								</div>
								<img
									v-if="icon.type == 'pic'"
									:src="icon.pic"
									class="icon-pic"
								/>
							</div>
							<div
								class="back voyage big-icon"
								:style="{ background: icon.bg }"
							>
								<div v-if="icon.type == 'pic'" class="big-icon icon-title">
									{{ icon.name }}
								</div>
							</div>
						</div>
					</div>
				</div></transition
			>
		</div>
	</div>
</template>
<style scoped>
	.big-icon-position {
		margin: auto !important;
		margin-top: 60px !important;
	}
	.big-holder {
		width: 100%;
		position: relative;
		height: 400px;
	}
	.big-icon {
		width: 294px !important;
		height: 294px !important;
		line-height: 294px !important;
		font-size: 28px !important;

		position: relative;
		z-index: 10;
	}

	.icon {
		width: 145px;
		height: 145px;
		margin: 5px 5px; /*px*/
	}
	.scent {
		/* background: url("../assets/bg_scent.jpg"); */
		background-size: cover;
		background-position: center center;
	}

	.page {
		/* overflow: hidden; */
		background-image: url("../assets/bg_scent.png");
		background-size: cover;
		background-position: center;
		touch-action: none;
	}
	.title {
		text-align: center;
		margin-top: 180px;
		font-size: 30px;
		color: #4d3524;
	}
	.title2 {
		text-align: center;
		margin-top: 45px;
		font-size: 30px;
		color: #4d3524;
	}
	.col {
		width: 33%;
		display: flex;
		flex-direction: column;
		align-content: center;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		/* background: green; */
		/* opacity: 0.5; */
	}
	.icon-list {
		/* background: yellow; */
		width: 76vw;
		margin: auto;
		display: flex;
		margin-top: 66px;
		/* top: 9rem; */
		padding-bottom: 45px;
		justify-content: space-around;
	}
	.icon-title {
		color: white;
		font-size: 20px;
		/* line-height: 160px; */
		height: 100%;

		text-align: center;
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		z-index: 1;
		display: flex;
		justify-content: center;
		flex-wrap: nowrap;
		flex-direction: row;
		align-content: center;
		align-items: center;
	}
	.icon-title2 {
		color: white;

		/* line-height: 160px; */
		text-align: center;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 5;
		display: flex;
		justify-content: center;
		flex-wrap: nowrap;
		flex-direction: row;
		align-content: center;
		align-items: center;
	}
	.icon-pic {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
	/* entire container, keeps perspective */
	.flip-container {
		perspective: 1000;
	}
	/* flip the pane when hovered */

	.hover-p .flipper {
		transform: rotateY(180deg);
	}

	.flip-container,
	.front,
	.back {
		width: 160px;
		height: 160px;
	}

	/* flip speed goes here */
	.flipper {
		transition: 0.6s;
		transform-style: preserve-3d;

		position: relative;
	}

	/* hide back of pane during swap */
	.front,
	.back {
		backface-visibility: hidden;
		position: absolute;
		top: 0;
		left: 0;
	}

	/* front pane, placed above back */
	.front {
		z-index: 2;
		font-size: 25px;
	}

	/* back, initially hidden pane */
	.back {
		transform: rotateY(180deg);
	}
</style>
<script>
	import Velocity from "velocity-animate";
	import vMenu from "../components/menu.vue";

	export default {
		name: "Scent",
		data() {
			return {
				icon: {
					col: 0,
					type: "pic",
					name: '"meh meh"',
					bg: "#242D1B ",
					// color: "#493627",
					path: "item/mehmeh",
					pic: require("../assets/list_mehmeh.png"),
				},

				currentKey: "",
				loaded: false,
				iconList1: [
					// { col: 1, type: "color", bg: "#FFFAED" },

					{
						col: 1,
						type: "pic",
						name: "the cotton",
						innerName: "the cotton",
						bg: "#F7F0EB ",
						// color: "#493627",
						path: "item/cotton",
						pic: require("../assets/list_cotton.png"),
					},
					{ col: 1, type: "color", bg: "#DCB5A5" },
					{
						col: 1,
						type: "pic",
						name: "pink salt",
						innerName: "pink salt",
						bg: "#EFCECD ",
						// color: "#493627",
						path: "item/pinksalt",
						pic: require("../assets/list_pinksalt.png"),
					},

					// { col: 1, type: "color", bg: "#EFCECD" },
					{ col: 1, type: "color", bg: "#A36157" },
					{
						col: 1,
						type: "pic",
						name: "the soil",
						innerName: "the soil",
						bg: "#85463A ",
						// color: "#493627",
						path: "item/soil",
						pic: require("../assets/list_soil.png"),
					},

					{
						col: 1,
						type: "pic",
						name: "dashing leather",
						innerName: "dashing<br>leather",
						bg: "#3C281B ",

						path: "item/leather",
						pic: require("../assets/list_leather.png"),
					},

					// { col: 1, type: "color", bg: "#3C281B" },
				],
				iconList2: [
					// { col: 2, type: "color", bg: "#CFE2D0" },

					{
						col: 2,
						type: "pic",
						name: "the mint",
						innerName: "the mint",
						bg: "#CFE2D0",
						path: "item/mint",
						pic: require("../assets/list_mint.png"),
					},

					{
						col: 2,
						type: "pic",
						name: "the moss",
						innerName: "the moss",
						bg: "#85916E",
						path: "item/moss",
						pic: require("../assets/list_moss.png"),
					},

					// { col: 2, type: "color", bg: "#726F4E " },
					{ col: 2, type: "color", bg: "#D29E53" },
					{ col: 2, type: "color", bg: "#A45A21" },
					{ col: 2, type: "color", bg: "#333B27" },
					{
						col: 2,
						type: "pic",
						name: "the olive",
						innerName: "the olive",
						bg: "#52511E",
						path: "item/olive",
						pic: require("../assets/list_olive.png"),
					},
					// {
					// 	col: 2,
					// 	type: "pic",
					// 	name: '"meh meh"',
					// 	bg: "#3C4732",
					// 	path: "item/mehmeh",
					// 	// pic: require("../assets/list_mehmeh.png"),
					// },

					// { col: 2, type: "color", bg: "#303725" },
				],
				iconList3: [
					{ col: 3, type: "color", bg: "#ECEFEE" },
					{ col: 3, type: "color", bg: "#C3BEBC" },
					{ col: 3, type: "color", bg: "#436D7C " },

					{
						col: 3,
						type: "pic",
						name: "dead sea",
						innerName: "dead sea",
						bg: "#8EACBE",
						path: "item/deadsea",
						pic: require("../assets/list_deadsea.png"),
					},

					{ col: 3, type: "color", bg: "#8EAFD8" },
					{ col: 3, type: "color", bg: "#14223F" },
				],
			};
		},
		methods: {
			clearPage(val, callback) {
				callback("ok");
			},
			beforeEnter: function (el) {
				el.style.opacity = 0;
			},
			enter: function (el, done) {
				Velocity(
					el,
					{ opacity: 1 },
					{ duration: 600, delay: Math.random() * 1200 },
					{ complete: done }
				);
			},
			// leave: function (el, done) {
			// 	Velocity(el, { translateX: "15px", rotateZ: "50deg" }, { duration: 600 });
			// 	Velocity(el, { rotateZ: "100deg" }, { loop: 2 });
			// 	Velocity(
			// 		el,
			// 		{
			// 			rotateZ: "45deg",
			// 			translateY: "30px",
			// 			translateX: "30px",
			// 			opacity: 0,
			// 		},
			// 		{ complete: done }
			// 	);
			// },
			goto(name) {
				console.log(name);
				if (this.$route.name == `/${name}`) {
					//
				} else {
					this.$router.replace(name);
				}
			},
			tap(icon, key) {
				// console.log(icon);
				let that = this;
				//NOT WORKING
				// console.log(key);
				if (icon.path) {
					that.currentKey = key;
					setTimeout(function () {
						that.goto(icon.path);
					}, 800);
				}
			},
		},
		components: { vMenu },
		computed: {},
		mounted() {
			this.loaded = true;
			this.$ba.trackPageview(this.$route.path);
		},
		activated() {
			this.currentKey = "";
		},
	};
</script>
