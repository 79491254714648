<!--
 * @Date: 2021-07-15 18:01:17
 * @LastEditors: Woz
 * @LastEditTime: 2021-09-18 20:03:45
 * @FilePath: \groundle_mobile\src\views\Contact.vue
-->
<template>
	<div class="scent page hide-scrollbar">
		<vMenu @beforeLeave="clearPage"></vMenu>

		<div class="header"></div>
		<div class="main">
			<div class="contact voyage">CONTACT</div>

			<div class="brand-line voyage">_________</div>
			<br />
			<br />

			<div class="link-holder SourceHanSerifSemiBold">
				<div class="link-unit">
					<!-- <div>微信公众号</div> -->
					<div>
						<img src="../assets/icon_wechat.png" class="icon" />
					</div>
					<div>groundle</div>
				</div>

				<div class="link-unit" @click="goto('https://weibo.com/groundle')">
					<!-- <div>微博</div> -->
					<img src="../assets/icon_weibo.png" class="icon" />
					<div>groundle_official</div>
				</div>

				<div
					class="link-unit"
					@click="
						goto(
							'https://www.xiaohongshu.com/user/profile/600e76ab000000000101de4d?xhsshare=CopyLink&appuid=600e76ab000000000101de4d&apptime=1630925656'
						)
					"
				>
					<!-- <div>小红书</div> -->
					<img src="../assets/icon_red.png" class="icon" />
					<div>groundle</div>
				</div>
				<div class="link-unit">
					<!-- <div>微信公众号</div> -->
					<div>
						<img src="../assets/icon_tmall.png" class="icon" />
					</div>
					<div>groundle</div>
				</div>

				<div
					class="link-unit"
					@click="goto('https://www.instagram.com/groundle_official/')"
				>
					<!-- <div>instagram</div> -->
					<img src="../assets/icon_ins.png" class="icon" />
					<div>groundle_official</div>
				</div>

				<div class="link-unit">
					<!-- <div class="SourceHanSerifSemiBold">业务联系人</div> -->

					<img src="../assets/icon_email.png" class="icon" />
					<div class="SourceHanSerifSemiBold">
						<a href="mailto:info@groundle.com">info@groundle.com</a>
					</div>

					<!-- <div class="SourceHanSerifSemiBold">19512162362</div> -->
				</div>
			</div>
		</div>

		<vFooter></vFooter>
	</div>
</template>
<style scoped>
	.icon {
		width: 48px;
	}
	a {
		/* text-decoration: none; */
		color: #4d3524;
	}
	.contact {
		font-size: 30px;
		padding-top: 101px;
	}
	.link-unit {
		margin-top: 30px;
	}
	.page {
		overflow-y: scroll;

		touch-action: pan-y;
	}
	.content {
		width: 100%;
	}
	.header {
		height: 510px;
		width: 100%;
		margin-top: 115px;
		background-image: url("../assets/contact_header.png");
		background-size: cover;
	}
	.main {
		height: 984px;
		width: 100%;
		background: #efeff1;
		color: #4c3526;
		font-size: 24px;
	}
</style>
<script>
	import vMenu from "../components/menu.vue";
	import vFooter from "../components/footer.vue";
	export default {
		name: "Contact",
		data() {
			return {};
		},
		methods: {
			clearPage(val, callback) {
				callback("ok");
			},
			goto(path) {
				location.href = path;
			},
		},
		components: { vMenu, vFooter },
		computed: {},
		mounted() {
			this.$ba.trackPageview(this.$route.path);
		},
	};
</script>
