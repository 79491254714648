<!--
 * @Date: 2021-07-15 14:28:13
 * @LastEditors: Woz
 * @LastEditTime: 2021-09-18 13:04:40
 * @FilePath: \groundle_mobile\src\views\Map.vue
-->

<template>
	<div class="home page">
		<vMenu @beforeLeave="clearPage"></vMenu>

		<div class="cover" ref="cover" v-if="comming">
			<div class="holder logo-holder">
				<img ref="logo" src="../assets/logo_map.png" />
			</div>
			<div ref="lrc" class="lrc SourceHanSerifSemiBold lrc-holder holder">
				在groundle的世界里 听见气味
			</div>
		</div>

		<div class="earth">
			<div class="earth-roller">
				<transition name="fade">
					<div class="map-title-holder" v-show="!comming">
						<div class="map-title-h voyage">groundle scent world map</div>
						<div class="map-title-s SourceHanSerifSemiBold">
							explore it! see what you get
						</div>
						<img
							class="float-left-arrow icon-arrow"
							src="../assets/btn_arrow.png"
						/>

						<img
							class="float-right-arrow icon-arrow"
							src="../assets/btn_arrow.png"
						/>
					</div>
				</transition>

				<div class="map-ruler-holder" v-show="!comming">
					<img src="../assets/ruler.png" class="ruler-img" />
					<div class="map-ration SourceHanSerifSemiBold">500km</div>
				</div>

				<div ref="world" class="earth-holder" style="z-index: 2" v-if="comming">
					<img
						ref="mapcover"
						src="../assets/world_map_cover.png"
						class="earth-map map-cover"
					/>
				</div>
				<transition name="fade">
					<div
						ref="realWorld"
						class="earth-holder"
						:class="{ smooth: !isStart }"
						:style="{
							transform: 'translateX(' + left + 'px)',
							opacity: showMap ? 1 : 0,
						}"
					>
						<img
							src="../assets/world_map.jpg"
							@touchstart="touchStart"
							@touchmove="touchMove"
							@touchend="touchEnd"
							class="earth-map"
						/>

						<div
							class="placer"
							@click="gotoPlace"
							:style="{
								left: h * pageList[currentPage].position.left - 40 + 'px',
								top: h * pageList[currentPage].position.top + 'px',
							}"
						>
							<div class="icon-hover" :class="{ showPoint: showPoint }">
								<div class="icon-blur icon-blur-1"></div>
								<div class="icon-blur icon-blur-2"></div>
								<div class="icon-blur icon-blur-3"></div>
								<img
									v-if="currentItem.icon"
									class="icon-pic"
									:src="currentItem.icon"
								/>
							</div>
						</div>
					</div>
				</transition>
			</div>
		</div>
	</div>
</template>
<style scoped>
	.icon-arrow {
		height: 18px;
	}

	.float-left-arrow {
		position: absolute;
		top: 46vh;
		left: 0;
	}
	.float-right-arrow {
		position: absolute;
		top: 46vh;
		left: 89vw;

		transform: rotate(180deg);
	}
	.map-title-h {
		font-size: 42px;
		color: #4d3524;
		text-align: left;
		width: 80vw;
	}
	.map-title-s {
		font-size: 21px;
		color: #4d3524;
		text-align: left;
		width: 80vw;
	}
	.map-title-holder {
		position: absolute;
		width: 100%;
		margin-left: 28px;
		margin-top: 28px;

		transition: 0.5s opacity ease-in;
		z-index: 2;
	}
	.map-ruler-holder {
		position: absolute;
		left: 80vw;
		bottom: 2vw;
		display: flex;
		align-items: center;
		transition: 0.5s opacity ease-in;
		z-index: 2;
	}
	.ruler-img {
		width: 73px;
		/* display: inline; */
		margin-right: 8px;
	}

	.map-ration {
		font-size: 17px;
		/* display: inline; */
		color: #4d3524;
	}
	.map-cover {
		z-index: 1;
	}
	.logo-holder {
		top: 45vh;
	}
	.logo-holder img {
		width: 280px;
		opacity: 0;
	}
	.lrc-holder {
		top: 45vh;
	}
	.lrc {
		color: #4d3524;
		opacity: 0;
		font-size: 30px;
	}
	.blur {
		filter: blur(35px) !important;
	}

	.cover {
		width: 100%;
		height: calc(100% - 116px);
		position: absolute;
		top: 116px;
		z-index: 5;
	}
	.icon-blur {
		width: 100%;
		height: 100%;
		position: absolute;
		/* margin: 0 5px; */
		border-radius: 50%;
		transition: 0.4s all ease-out;
		background: white;
	}
	.icon-hover {
		opacity: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		transition: 0.4s all ease-out;
		display: flex;
		justify-content: center;
	}
	.showPoint {
		opacity: 1 !important;
	}
	.icon-blur-1 {
		opacity: 0.4;
	}
	.icon-blur-2 {
		opacity: 0.4;
	}
	.icon-blur-3 {
		opacity: 0.4;
	}
	.showPoint .icon-blur-1 {
		-webkit-transform: scale(1.18);
		transform: scale(1.18);
	}

	.showPoint .icon-blur-2 {
		-webkit-transform: scale(1.75);
		transform: scale(1.75);
	}

	.showPoint .icon-blur-3 {
		-webkit-transform: scale(2.76);
		transform: scale(2.76);
	}
	.placer {
		width: 80px;
		height: 80px;
		/* background: yellow; */
		position: absolute;
		z-index: 100;
	}
	.icon-pic {
		cursor: pointer;
		height: 100%;
		position: relative;
		z-index: 5;
		margin: auto;
	}
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.6s ease-in;
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}

	.page {
		touch-action: none;
		overflow: hidden;
		background-size: cover;
		background-position: center center;
	}

	.earth-map {
		position: absolute;
		left: 0;
		height: 100%;
		transition: all 0.5s ease-out;
	}
	.earth {
		height: calc(100% - 116px);
		position: absolute;
		top: 116px;
	}
	.earth-holder {
		height: 100%;
		position: absolute;
		top: 0;
	}
	.smooth {
		transition: all 0.4s ease-out;
	}
	.earth-roller {
		position: absolute;
		height: 100%;
	}
</style>
<script>
	import Velocity from "velocity-animate";
	import VelocityUI from "velocity-ui-pack";

	import vMenu from "../components/menu.vue";
	export default {
		name: "Map",
		data() {
			return {
				showMap: false,
				comming: true,
				showPoint: false,
				currentPage: 0,
				moveFlag: false,
				w: 1,
				h: 1,
				isStart: false,
				rolling: 0,
				clientStart: 0,
				positionStart: 0,
				left: -800,
				currentItem: {},
				pageList: [
					{
						item: "soil",
						position: { left: "188", top: "42" },
						margin: +100,
					},
					{
						item: "leather",
						position: { left: "190", top: "31" },
						margin: +50,
					},
					{
						item: "mehmeh",
						position: { left: "198", top: "39" },
						margin: 0,
					},
					{
						item: "olive",
						position: { left: "200", top: "42" },
						margin: -150,
					},
					{
						item: "mint",
						position: { left: "205", top: "50" },
						margin: -150,
					},
					{
						item: "deadsea",
						position: { left: "210", top: "50" },
						margin: -100,
					},
					{
						item: "moss",
						position: { left: "260", top: "35" },
					},

					{
						item: "cotton",
						position: { left: "330", top: "50" },
						margin: 150,
					},
					{
						item: "pinksalt",
						position: { left: "332", top: "50" },
						margin: +150,
					},
				],

				itemList: {
					olive: {
						name: "The Moss",
						subtitle: "In Aomori",
						path: "item/olive",
						// bg: require("../assets/main_olive.jpg"),
						icon: require("../assets/icon_olive.png"),
						// cover: require("../assets/cover_olive.png"),
					},
					soil: {
						name: "Soil",
						subtitle: "In Aomori",
						path: "item/soil",
						// bg: require("../assets/main_moss.jpg"),
						icon: require("../assets/icon_soil.png"),
						// cover: require("../assets/cover_moss.png"),
					},
					moss: {
						name: "The Moss",
						subtitle: "In Aomori",
						path: "item/moss",
						// bg: require("../assets/main_moss.jpg"),
						icon: require("../assets/icon_moss.png"),
						// cover: require("../assets/cover_moss.png"),
					},

					deadsea: {
						name: "Dead Sea",
						subtitle: "In Amman",
						path: "item/deadsea",

						// bg: require("../assets/main_deadsea.jpg"),
						icon: require("../assets/icon_deadsea.png"),

						// cover: require("../assets/cover_deadsea.png"),
					},
					cotton: {
						name: "The Cotton",
						subtitle: "In Carribean",
						path: "item/cotton",

						icon: require("../assets/icon_cotton.png"),
						// bg: require("../assets/main_cotton.jpg"),
						// cover: require("../assets/cover_cotton.png"),
					},

					pinksalt: {
						name: "Pink Salt",
						subtitle: "In Bahamas",
						path: "item/pinksalt",

						icon: require("../assets/icon_pinksalt.png"),
						// bg: require("../assets/main_cotton.jpg"),
						// cover: require("../assets/cover_mehmeh.png"),
					},
					mehmeh: {
						name: "Meh Meh",
						subtitle: "In Valais",
						path: "item/mehmeh",

						icon: require("../assets/icon_mehmeh.png"),
						// bg: require("../assets/main_cotton.jpg"),
						// cover: require("../assets/cover_mehmeh.png"),
					},
					mint: {
						name: "Mint",
						subtitle: "In Nile",
						path: "item/mint",

						icon: require("../assets/icon_mint.png"),
						// bg: require("../assets/main_cotton.jpg"),
						// cover: require("../assets/cover_mehmeh.png"),
					},

					leather: {
						name: "leather",
						subtitle: "In Savile Row",
						path: "item/leather",

						icon: require("../assets/icon_leather.png"),
						// bg: require("../assets/main_cotton.jpg"),
						// cover: require("../assets/cover_mehmeh.png"),
					},
				},
			};
		},
		methods: {
			clearPage(val, callback) {
				callback("ok");
			},
			goto(name) {
				// console.log(name);
				if (this.$route.name == name) {
					//
				} else {
					this.$router.replace(name);
				}
			},
			touchStart(e) {
				// console.log('touchstart')
				// console.log("touch start");
				e.preventDefault();
				e.stopPropagation();
				this.rolling = false;
				//停止滚动
				this.isStart = true;
				//开始
				const { clientX, clientY } = e.touches ? e.touches[0] : e;
				this.clientStart = clientX;
				this.positionStart = this.left;
				this.moveFlag = false;
			},
			touchMove(e) {
				// console.log('touchMove')
				if (!this.isStart) return;

				e.preventDefault();
				e.stopPropagation();

				const { clientX, clientY } = e.touches ? e.touches[0] : e;

				// console.log(clientX);
				let deltaX = clientX - this.clientStart;
				// console.log(deltaX);
				this.left = this.positionStart + deltaX;
				this.moveFlag = true;
				this.showPoint = false;
			},

			touchEnd(e) {
				// console.log("touch end");
				e.preventDefault();
				e.stopPropagation();

				this.isStart = false;

				if (this.moveFlag) {
					//滑动
					//check point
					if (this.left - this.positionStart > 20 * this.w) {
						// console.log("left");
						this.movePre();
					} else if (this.left - this.positionStart < -20 * this.w) {
						// console.log("right");
						this.moveNext();
					} else {
						//回到原来位置
						this.left = this.positionStart;
						this.showPoint = true;
					}
				} else {
					//点击
					// let path = this.currentItem.path.split("/");

					// // this.goto(path[0] + "/" + "render-" + path[1]);

					// this.goto(this.currentItem.path);
					this.gotoPlace();
				}
			},
			gotoPlace() {
				let path = this.currentItem.path.split("/");

				// this.goto(path[0] + "/" + "render-" + path[1]);

				this.goto(this.currentItem.path);
			},
			moveNext() {
				let flag = 0;
				if (this.currentPage == this.pageList.length - 1) {
					this.currentPage = 0;
					flag = 1;
				} else {
					this.currentPage++;
				}
				this.setPage(this.currentPage, flag);
			},
			movePre() {
				let flag = 0;
				if (this.currentPage == 0) {
					this.currentPage = this.pageList.length - 1;
					flag = -1;
				} else {
					this.currentPage--;
				}
				this.setPage(this.currentPage, flag);
			},
			setPage(index, flag) {
				console.log(index, flag);
				let that = this;
				that.showPoint = false;
				this.currentPage = index;
				let left =
					-this.h * this.pageList[this.currentPage].position.left +
					50 * this.w +
					(this.pageList[this.currentPage].margin || 0);
				if (flag == 0) {
					this.left = left;
					setTimeout(function () {
						that.currentItem =
							that.itemList[that.pageList[that.currentPage].item];
						that.showPoint = true;
					}, 420);
				}
				if (flag == -1) {
					let offset = ((this.h * 100) / 1080) * 1912;
					this.left = left + offset;
					setTimeout(function () {
						that.isStart = true;
						that.left = left;
						that.currentItem =
							that.itemList[that.pageList[that.currentPage].item];
						that.showPoint = true;
					}, 420);
				}
				if (flag == 1) {
					let offset = ((this.h * 100) / 1080) * 1912;
					this.left = left - offset;

					setTimeout(function () {
						that.isStart = true;
						that.left = left;
						that.currentItem =
							that.itemList[that.pageList[that.currentPage].item];

						that.showPoint = true;
					}, 420);
				}
			},
			initPage() {
				console.log("init page");
				const log = this.$refs.logo;
				const lrc = this.$refs.lrc;
				const cover = this.$refs.cover;
				let index = 0;
				let world = this.$refs.world;
				let realWorld = this.$refs.realWorld;

				let mapCover = this.$refs.mapcover;
				let left =
					-this.h * this.pageList[index].position.left +
					50 * this.w +
					(this.pageList[this.currentPage].margin || 0);

				this.left = left;
				let initLeft = left + 100 * this.w;

				this.currentItem = this.itemList[this.pageList[index].item];

				let that = this;
				var loadingMapSeq = [
					{
						e: log,
						p: { opacity: 1, translateY: "-5vw" },
						o: {
							duration: 1000,
							begin: function () {
								console.log("start");

								Velocity(
									world,
									{ translateX: [left, initLeft] },
									{
										duration: 4000,
										complete: function () {},
									}
								);
							},
						},
					},
					{
						e: log,
						p: { opacity: 0, translateY: "-5vw" },
						o: { duration: 1000, delay: 500 },
					},

					{
						e: lrc,
						p: { opacity: 1, translateY: "-5vw" },
						o: {
							duration: 800,
							delay: 500,
							sequenceQueue: false,
						},
					},
					{
						e: cover,
						p: { opacity: 0 },
						o: {
							duration: 600,
							delay: 1500,
							sequenceQueue: false,
							complete: function () {
								that.showMap = true;
							},
						},
					},

					{
						e: mapCover,
						p: { opacity: 0 },
						o: {
							duration: 600,
							delay: 500,
							sequenceQueue: false,
							complete: function () {
								that.comming = false;
								that.showPoint = true;
								console.log("end");
							},
						},
					},
				];

				Velocity.RunSequence(loadingMapSeq);
			},
		},
		watch: {},
		components: { vMenu },
		computed: {},
		mounted() {
			// 初始化屏幕宽度 地图高度
			this.h = this.$refs.world.offsetHeight / 100;
			this.w = document.body.clientWidth / 100;
			// this.setPage(0, 0);
			this.initPage();
			this.$ba.trackPageview(this.$route.path);

			document.oncontextmenu = function (e) {
				e.preventDefault();
			};
		},
	};
</script>
