<!--
 * @Date: 2021-08-23 18:01:48
 * @LastEditors: Woz
 * @LastEditTime: 2021-08-25 17:10:28
 * @FilePath: \groundle_mobile\src\views\dev.vue
-->
<template>
	<div
		style="
			background: blue;
			height: 100%;
			position: absolute;
			width: 100%;
			overflow: hidden;
		"
	>
		<div>rotate:{{ angDeg }}</div>
		<img
			ref="earth"
			:style="{ transform: 'rotateZ(' + ang + 'rad)' }"
			src="../assets/obj_earth.png"
			@touchstart="touchStart"
			@touchmove="touchMove"
			style="width: 100vw; position: absolute; bottom: -50px; left: 0"
		/>
	</div>
</template> 

<script>
	export default {
		data() {
			return {
				ang: 0,
				angStart: 0,
				isStart: false,
				angDeg: 0,
				pointerList: [
					{
						rotate: "-34deg",
						rotateR: "34deg",
						item: "moss",
						position: { left: "109vw", top: "27vw" },
					},
					{
						item: "deadsea",
						position: { left: "78vw", top: "19vw" },
						rotate: "-10deg",
						rotateR: "10deg",
					},
					{
						item: "cotton",
						rotate: "37deg",
						rotateR: "-37deg",
						position: { left: "34vw", top: "28vw" },
					},
					{
						rotate: "78deg",
						rotateR: "-78deg",
						item: "moss",
						position: { left: "3vw", top: "61vw" },
					},
					{
						rotate: "108deg",
						rotateR: "-108deg",
						item: "deadsea",
						position: { left: "12vw", top: "93vw" },
					},

					{
						item: "cotton",
						rotate: "149deg",
						rotateR: "-149deg",
						position: { left: "32vw", top: "125vw" },
					},
					{
						rotate: "195deg",
						rotateR: "-195deg",
						item: "moss",
						position: { left: "85vw", top: "140vw" },
					},
					{
						rotate: "232deg",
						rotateR: "-232deg",
						item: "deadsea",
						position: { left: "109vw", top: "115vw" },
					},

					{
						item: "cotton",
						rotate: "277deg",
						rotateR: "-277deg",
						position: { left: "123vw", top: "78vw" },
					},
				],
			};
		},

		methods: {
			angXY: (ev, earth) => {
				const bcr = earth.getBoundingClientRect();

				const radius = bcr.width / 2;
				const { clientX, clientY } = ev.touches ? ev.touches[0] : ev;
				const y = clientY - bcr.top - radius; // y from center
				const x = clientX - bcr.left - radius; // x from center
				return Math.atan2(y, x);
			},

			touchStart(e) {
				e.preventDefault();
				e.stopPropagation();

				this.isStart = true;
				this.angStart = this.angXY(e, this.$refs.earth) - this.ang;
			},
			touchMove(e) {
				if (!this.isStart) return;
				e.preventDefault();
				e.stopPropagation();
				let ang = this.angXY(e, this.$refs.earth) - this.angStart;

				let angDeg = (ang / Math.PI) * 180;
				this.angDeg = angDeg;
				let that = this;
				let flag = false;
				this.pointerList.forEach((p) => {
					if (
						Number(p.rotate.replace("deg", "")) < angDeg + 5 &&
						Number(p.rotate.replace("deg", "")) > angDeg - 5
					) {
						console.log("stick");
						flag = p.rotate;
					}
				});

				if (flag != false) {
					that.ang = (Number(flag.replace("deg", "")) / 180) * Math.PI;
				} else {
					that.ang = ang;
				}
			},
			touchEnd(e) {
				e.preventDefault();
				e.stopPropagation();
				this.isStart = false;
			},
		},
	};
</script>

<style>
</style>