<!--
 * @Date: 2021-07-15 14:28:20
 * @LastEditors: Woz
 * @LastEditTime: 2021-09-18 20:07:45
 * @FilePath: \groundle_mobile\src\views\Item.vue
-->
 
<template>
	<div class="scent page hide-scrollbar">
		<vMenu @beforeLeave="clearPage"></vMenu>
		<transition name="fade">
			<div class="loading-cover" v-show="loading"></div>
		</transition>
		<full-page
			ref="fullpage"
			:skip-init="true"
			:options="options"
			id="fullpage"
		>
			<div class="section">
				<div
					class="header"
					v-bind:style="{
						'background-image': 'url(' + item.img_header + ')',
					}"
					:class="scrollTop > 8 ? 'mini-header' : ''"
					ref="header"
				>
					<img
						class="float-left-arrow icon-arrow"
						src="../assets/icon_arrow.png"
						@click="next(-1)"
					/>

					<img
						class="float-right-arrow icon-arrow"
						src="../assets/icon_arrow.png"
						@click="next(1)"
					/>

					<!-- <img
					class="float-bottom-arrow icon-arrow"
					src="../assets/icon_arrow.png"
				/> -->
					<div class="float-bottom-arrow SourceHanSansRegular">
						下滑查看更多
					</div>

					<div class="player holder">
						<!-- <div>
								<img
									src="../assets/icon_location.png"
									@click="goto('/Map')"
									class="icon-location"
								/>
							</div> -->

						<div class="player-holder">
							<div class="pause-holder">
								<img
									v-if="playing"
									src="../assets/btn_pause.png"
									@click="pause"
									class="btn-pause"
								/>
								<img
									v-if="!playing"
									src="../assets/btn_play.png"
									@click="start"
									class="btn-pause"
								/>
							</div>
							<div class="player-progress-holder">
								<div class="player-bar"></div>
								<div
									class="player-seeker"
									:style="{ left: progress * 100 + '%' }"
								></div>
							</div>
							<div class="val-icon">
								<img src="../assets/icon_vol.png" />
							</div>
							<div class="duration">{{ seek }}</div>
							<div class="comment SourceHanSansRegular">
								{{ item.location }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="section">
				<div class="main">
					<div>
						<div class="p-main">
							<img :src="item.img_content" class="content-pic" />

							<div class="btn-more voyage">
								More Product
								<span class="mini-more sourceHanSerif"
									>点击下方获取更多产品信息</span
								>
							</div>
						</div>
						<div class="p-swiper">
							<div
								class="p-swiper-pic"
								v-for="(img, index) in item.img_list"
								v-bind:key="img"
							>
								<img :src="img" @click="goto(item.link[index])" />
							</div>
						</div>
					</div>
				</div>
				<vFooter></vFooter>
			</div>
		</full-page>
	</div>
</template>
<style scoped>
	.mini-more {
		font-size: 15px;
		margin-left: 5px;
	}
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.1s ease-in;
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}

	.loading-cover {
		position: absolute;
		width: 100%;
		height: 100%;
		background: white;
		z-index: 1;
	}
	.btn-pause {
		height: 15px;
		padding: 15px;
		vertical-align: middle;
	}

	.mini-header {
		height: 0 !important;
	}
	.mini-header .player {
		bottom: unset !important;
		/* top: 40vw; */
		display: none;
	}

	.mini-header .icon-arrow {
		display: none;
	}
	.header {
		height: 100%;

		width: 100%;
		transition: 1000ms linear height;

		background-size: cover;
		background-position: center center;
	}

	.main {
		background: #f6f6f6;
		min-height: 100%;
	}
	.icon-location {
		width: 52px;
	}
	.p-main {
		-webkit-overflow-scrolling: auto;
		background-repeat: no-repeat;
		width: 101%;
		left: -1%;
		background-size: 100% auto;
		background-position: bottom;
		min-height: 190vw;
		position: relative;
		color: #493c33;
	}

	.content-pic {
		width: 100%;
		margin-top: 15vw;
	}

	.row {
		height: 45vw;
		width: 100%;
	}
	.col-6 {
		width: 50%;
		height: 45vw;
		float: left;
		font-size: 1.2rem;
	}
	.p-content {
		min-height: 400px;
		/* padding-top: 6rem; */
	}
	.col-txt-img {
		width: auto;
		height: 45vw;
	}
	.col-img {
		width: 90%;
	}
	.col-left {
		text-align: left;
	}
	.col-right {
		text-align: right;
	}
	.p-swiper {
		width: 100%;
		height: 28vw;
		display: flex;
		/* margin-top: -2vw; */
	}
	.p-swiper-pic {
		flex-grow: 1;
	}
	.p-swiper-pic img {
		width: 100%;
	}
	.btn-more {
		/* width: 100%; */
		height: 64px;
		font-size: 21px;
		text-align: left;
		margin-left: 21px;
		line-height: 64px;

		margin-top: -5px;
	}
	.p-title {
		font-size: 3.1rem;
	}
	.p-subtitle {
		font-size: 2.375rem;
	}
	.icon-arrow {
		max-width: 15px;
		max-height: 15px;
	}
	.float-bottom-arrow {
		position: absolute;
		bottom: 30px;
		left: 0;
		width: 100%;
		text-align: center;
		color: white;
		font-size: 20px;
		/* transform: rotate(-90deg); */
	}
	.float-left-arrow {
		position: absolute;
		top: 46vh;
		left: 0;
		padding: 5vw;
	}
	.float-right-arrow {
		position: absolute;
		top: 46vh;
		right: 0;
		padding: 5vw;
		transform: rotate(180deg);
	}
	.player {
		bottom: 20vw;
		transition: 500ms linear;
	}
	.player-holder {
		width: 365px;
		margin: auto;
		position: relative;
		display: flex;
		height: 80px;
		justify-content: center;
		align-items: center;
	}
	.comment {
		color: white;
		font-size: 18px;
		margin-top: 72px;
		width: 100%;
	}
	.pause-holder {
		position: absolute;
		right: -45px;
	}
	.player-progress-bar {
		width: 100%;
	}
	.val-icon {
		height: 24px;
		position: absolute;
		left: -30px;
		margin-left: -13px;
		vertical-align: middle;
	}
	.val-icon img {
		height: 24px;
	}
	.player-bar {
		width: 100%;
		height: 2px;
		background: white;
		position: absolute;
		/* top: 10px; */
		left: 0;
		opacity: 0.5;
	}
	.player-seeker {
		width: 10px;
		height: 10px;
		background: white;
		border-radius: 50%;
		position: absolute;
		margin-top: -4px;
		transition: left linear 0.3s;
	}
	.duration {
		position: absolute;
		right: -110px;
		/* top: 0.2rem; */
		font-size: 11px;
		color: white;
	}
	li {
		list-style-type: none;
		font-size: 1rem;
	}
</style>
<style >
	.iScrollVerticalScrollbar {
		display: none !important;
	}
</style>
<script>
	// import Velocity from "velocity-animate";
	import { Howl } from "howler";

	import vMenu from "../components/menu.vue";
	import vFooter from "../components/footer.vue";

	import items from "../contents/items";

	export default {
		name: "Item",
		data() {
			return {
				loading: true,
				options: {
					scrollBar: false,
					scrollOverflow: true,
					scrollOverflowOptions: {
						// probeType: 1,
						deceleration: 0.001,

						bounceTime: 300,
					},
					licenseKey: "0A5D6727-02894A2F-A632A1DE-E57F2BEB",
				},

				scrollTop: 0,
				progress: 0,
				seek: "00:00:00",
				total: 0,
				item: {},
				playing: false,
			};
		},
		methods: {
			clearPage(val, callback) {
				this.loading = true;
				console.log("clear page");
				clearInterval(this.interval);

				// console.log(this.sound);
				if (this.sound) {
					this.sound.stop();
					this.sound = null;
				}
				let that = this;
				// console.log("clear");
				setTimeout(function () {
					callback("ok");
					that.$refs.fullpage.api.destroy("all");
				}, 150);
			},

			goto(name) {
				// console.log("goto");

				if (this.$route.name == `/${name}`) {
					//
				} else {
					// this.sound = null;

					this.clearPage({}, () => {
						this.$router.replace(name);
					});
				}
			},
			doPlaySound() {
				let that = this;
				this.sound.play();

				this.playing = true;
				this.total = this.sound.duration();
				this.interval = setInterval(() => {
					updateWidth();
				}, 300);

				function formatDuraton(time) {
					if (time > -1) {
						var hour = Math.floor(time / 3600);
						var min = Math.floor(time / 60) % 60;
						var sec = time % 60;
						if (hour < 10) {
							time = "0" + hour + ":";
						} else {
							time = hour + ":";
						}

						if (min < 10) {
							time += "0";
						}
						time += min + ":";

						if (sec < 10) {
							time += "0";
						}
						time += sec;
					}
					return time;
				}

				function updateWidth() {
					if (that.sound.playing()) {
						that.progress = that.sound.seek() / that.sound.duration();
						that.seek = formatDuraton(Math.floor(that.sound.seek()));
					}
				}
			},

			initItem() {
				console.log(this.$route.params.product);
				let product = this.$route.params.product;

				let that = this;

				this.item = items[product];
				console.log(this.item);
				this.sound = new Howl({
					src: [this.item.sound],
					autoplay: true,
					loop: true,
				});

				this.options.onLeave = function (origin, destination, direction) {
					// console.log(origin);
					if (origin.index == 0) {
						console.log("sound off");
						// Howler.volume(0.5);
						that.sound.fade(1, 0, 500);
					}
					if (destination.index == 0) {
						//restore vol
						// Howler.volume(1);
						console.log("sound on");
						that.sound.fade(0, 1, 500);
					}
				};

				this.doPlaySound();

				that.$refs.fullpage.init();
				this.$ba.trackPageview(this.$route.path);

				setTimeout(function () {
					that.$refs.fullpage.api.reBuild();
					that.loading = false;
				}, 200);
			},
			pause() {
				this.sound.pause();

				this.playing = false;
			},
			start() {
				this.sound.play();
				this.playing = true;
			},

			next(forward) {
				let list = Object.keys(items);
				let index = this.item.index;

				if (forward == 1) {
					this.goto(index >= list.length - 1 ? list[0] : list[index + 1]);
				} else {
					this.goto(index <= 0 ? list[list.length - 1] : list[index - 1]);
				}
			},

			swipeHandler(direction) {
				console.log(direction); // May be left / right / top / bottom
				if (direction == "left") {
					// do next page
					this.next(-1);
				}
				if (direction == "right") {
					// do next page
					this.next(1);
				}
			},
		},
		components: { vMenu, vFooter },
		computed: {},
		mounted() {
			this.initItem();
		},

		destroyed() {},
		watch: {
			$route(to, from) {
				if (to.name == "Item") {
					if (to != from) {
						this.initItem();
					}
				}
			},
		},
	};
</script>
