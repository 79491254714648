<!--
 * @Date: 2021-07-15 12:47:28
 * @LastEditors: Woz
 * @LastEditTime: 2021-09-17 14:51:47
 * @FilePath: \groundle_mobile\src\views\About.vue
-->

<template>
	<div class="scent page hide-scrollbar">
		<vMenu @beforeLeave="clearPage"></vMenu>

		<div class="header"></div>
		<div class="main">
			<div class="contact voyage">BRAND STORY</div>

			<div class="brand-line voyage">_________</div>
			<br />

			<div class="link-holder SourceHanSerifSemiBold">
				<div class="link-unit">
					<span class="voyage">「groundle」</span>
					是一个将自然主义贯穿气味美学的香氛品牌
					<br />
					取意于 <span class="voyage">ground</span> 和<span class="voyage">
						candle
					</span>
					的结合
					<br />
					<br />
					品牌将精神理念立足于大地万物
					<br />
					用独特的叙事视角将自然香气融入呼吸之间
					<br />
					在自然风物主导的氛围之下
					<br />
					<span class="voyage">groundle </span> 以地域性特色为线索
					<br />
					搜寻自然辽远与不甚细微的一切
					<br />用从大地上生长出来的香气，放松情绪
					<br />
					<br />
					脚踩在大地上 <br /><span class="voyage">groundle </span>
					不急不缓地相信

					<br />借着自然中流动的能量 <br />
					身心可以在生活一隅中妥善安放。
				</div>
			</div>
		</div>

		<vFooter></vFooter>
	</div>
</template>
<style scoped>
	.contact {
		font-size: 30px;
		padding-top: 101px;
	}
	.link-unit {
		margin-top: 49px;
	}
	.page {
		overflow-y: scroll;

		touch-action: pan-y;
	}
	.content {
		width: 100%;
	}
	.header {
		height: 510px;
		width: 100%;
		margin-top: 115px;
		background-image: url("../assets/about_header.png");
		background-size: cover;
	}
	.main {
		height: 860px;
		width: 100%;
		background: #efeff1;
		color: #4d3524;
		font-size: 24px;
	}
</style>
<script>
	import vMenu from "../components/menu.vue";
	import vFooter from "../components/footer.vue";
	export default {
		name: "About",
		data() {
			return {};
		},
		methods: {
			clearPage(val, callback) {
				callback("ok");
			},
		},
		components: { vMenu, vFooter },
		computed: {},
		mounted() {
			this.$ba.trackPageview(this.$route.path);
		},
	};
</script>
