<!--
 * @Date: 2021-07-15 13:55:50
 * @LastEditors: Woz
 * @LastEditTime: 2021-09-17 14:47:39
 * @FilePath: \groundle_mobile\src\components\footer.vue
-->
<template>
	<div class="footer">
		<!-- <div class="form-holder">
			<div class="voyage">Sign up for Officine Universelle Buly updates</div>

			<div class="input-holder">
				<input class="input" placeholder="Email address" />
				<div class="input-button">Submit</div>
			</div>
		</div> -->

		<div class="link-holder">
			<!-- <div class="link voyage">Wechat</div> -->

			<div
				class="link voyage"
				@click="
					goto(
						'https://www.xiaohongshu.com/user/profile/600e76ab000000000101de4d?xhsshare=CopyLink&appuid=600e76ab000000000101de4d&apptime=1630925656'
					)
				"
			>
				Red
			</div>
			<div class="link voyage" @click="goto('https://weibo.com/groundle')">
				Weibo
			</div>
			<div
				class="link voyage"
				@click="goto('https://www.instagram.com/groundle_official/')"
			>
				Instagram
			</div>
		</div>
		<div
			@click="goto('https://beian.miit.gov.cn')"
			class="icp SourceHanSerifRegular"
		>
			上海光壤品牌管理有限公司拥有及运营@沪ICP备2021014854号-1
		</div>
	</div>
</template>

<script>
	export default {
		name: "vFooter",
		props: {},
		data() {
			return {};
		},
		methods: {
			goto(path) {
				location.href = path;
			},
		},
	};
</script>
<style scoped>
	.footer {
		color: #180202;
		width: 100%;
		height: 258px;
		background: white;
		font-size: 20px;
		display: flex;
		position: relative;
	}

	.form-holder {
		text-align: center;
		width: 100%;
	}
	.link-holder {
		margin: auto;
		width: 100%;
		text-align: center;
	}
	.link {
		margin: 0 16px 0 16px;
		cursor: pointer;
		display: inline;
		font-size: 25px;
	}
	.icp {
		cursor: pointer;
		font-size: 20px;
		position: absolute;
		width: 100%;
		text-align: center;
		bottom: 42px;
	}
</style>