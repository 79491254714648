/*
 * @Date: 2021-07-21 15:49:44
 * @LastEditors: Woz
 * @LastEditTime: 2021-09-15 20:32:12
 * @FilePath: \groundle_mobile\src\contents\items.js
 */

const items = {
    moss: {
        index: 0,
        location: 'Aomori, Japan',
        name: "moss",
        img_header: require("../assets/item_moss.jpg"),

        sound: require("../assets/mp3/moss.mp3"),
        title: "The Moss In Aomori",
        subtitle: "苔藓",
        img_content: require("../assets/products/moss/content.png"),
        img_list: [
            require("../assets/products/moss/s1.png"),
            require("../assets/products/moss/s2.png"),
            require("../assets/products/moss/s3.png"),
            require("../assets/products/moss/s4.png")
        ],
        link: ['/item/deadsea', '/item/cotton', '/item/deadsea', '/item/cotton',],
    },
    deadsea: {
        index: 1,
        title: "The Dead Sea In Amman ",
        subtitle: "死海",
        location: 'Amman, West Asia',
        name: "deadsea",
        img_content: require("../assets/products/deadsea/content.png"),
        img_list: [
            require("../assets/products/deadsea/s1.png"),
            require("../assets/products/deadsea/s2.png"),
            require("../assets/products/deadsea/s3.png"),
            require("../assets/products/deadsea/s4.png")
        ],
        link: ['/item/moss', '/item/cotton', '/item/moss', '/item/cotton',],
        img_header: require("../assets/item_deadsea.jpg"),
        sound: require("../assets/mp3/deadsea.mp3"),
    },
    cotton: {

        index: 2,

        name: "cotton",
        location: 'Caribbean, South America',
        title: "The Cotton In Carribean",
        subtitle: "海绵岛",
        img_content: require("../assets/products/cotton/content.png"),
        img_list: [
            require("../assets/products/cotton/s1.png"),
            require("../assets/products/cotton/s2.png"),
            require("../assets/products/cotton/s3.png"),
            require("../assets/products/cotton/s4.png")
        ],
        link: ['/item/deadsea', '/item/moss', '/item/deadsea', '/item/moss',],

        img_header: require("../assets/item_cotton.jpg"),
        sound: require("../assets/mp3/cotton.mp3"),
    },

    olive: {

        index: 3,

        name: "olive",
        location: 'Granada, Spain',
        title: "The Olive In Granada",
        subtitle: "橄榄",
        img_content: require("../assets/products/olive/content.png"),
        img_list: [
            require("../assets/products/olive/s1.png"),
            require("../assets/products/olive/s2.png"),
            require("../assets/products/olive/s3.png"),
            require("../assets/products/olive/s4.png")
        ],
        link: ['/item/soil', '/item/cotton', '/item/soil', '/item/cotton',],

        img_header: require("../assets/item_olive.jpg"),
        sound: require("../assets/mp3/olive.mp3"),
    },
    soil: {

        index: 4,

        name: "soil",
        location: 'Ninfa, Italy',
        title: "The Soil In Ninfa",
        subtitle: "泥土蔷薇",
        img_content: require("../assets/products/soil/content.png"),
        img_list: [
            require("../assets/products/soil/s1.png"),
            require("../assets/products/soil/s2.png"),
            require("../assets/products/soil/s3.png"),
            require("../assets/products/soil/s4.png")
        ],
        link: ['/item/olive', '/item/cotton', '/item/olive', '/item/cotton',],

        img_header: require("../assets/item_soil.jpg"),
        sound: require("../assets/mp3/soil.mp3"),
    },
    mehmeh: {

        index: 5,

        name: "mehmeh",
        location: 'Valais , Switzerland',
        title: "\"meh meh\" in Valais",
        subtitle: "咩咩",
        img_content: require("../assets/products/mehmeh/content.png"),
        img_list: [
            require("../assets/products/mehmeh/s1.png"),
            require("../assets/products/mehmeh/s2.png"),
            require("../assets/products/mehmeh/s3.png"),
            require("../assets/products/mehmeh/s4.png")
        ],
        link: ['/item/olive', '/item/soil', '/item/olive', '/item/soil',],

        img_header: require("../assets/item_mehmeh.jpg"),
        sound: require("../assets/mp3/mehmeh.mp3"),
    },

    pinksalt: {

        index: 6,

        name: "pinksalt",
        location: 'Bahamas',
        title: "Pink Salt in Bahamas",
        subtitle: "粉盐",
        img_content: require("../assets/products/pinksalt/content.png"),
        img_list: [
            require("../assets/products/pinksalt/s1.png"),
            require("../assets/products/pinksalt/s2.png"),
            require("../assets/products/pinksalt/s3.png"),
            require("../assets/products/pinksalt/s4.png")
        ],
        link: ['/item/mehmeh', '/item/olive', '/item/mehmeh', '/item/olive',],

        img_header: require("../assets/item_pinksalt.jpg"),
        sound: require("../assets/mp3/pinksalt.mp3"),
    },

    mint: {

        index: 7,

        name: "mint",
        location: 'Nile',
        title: "the mint in Nile",
        subtitle: "薄荷",
        img_content: require("../assets/products/mint/content.png"),
        img_list: [
            require("../assets/products/mint/s1.png"),
            require("../assets/products/mint/s2.png"),
            require("../assets/products/mint/s3.png"),
            require("../assets/products/mint/s4.png")
        ],
        link: ['/item/pinksalt', '/item/cotton', '/item/pinksalt', '/item/cotton',],

        img_header: require("../assets/item_mint.jpg"),
        sound: require("../assets/mp3/mint.mp3"),
    },

    leather: {

        index: 8,
        name: "leather",
        location: 'Savile Row',
        title: "dashing leather in Savile Row",
        subtitle: "皮革烟草",
        img_content: require("../assets/products/leather/content.png"),
        img_list: [
            require("../assets/products/leather/s1.png"),
            require("../assets/products/leather/s2.png"),
            require("../assets/products/leather/s3.png"),
            require("../assets/products/leather/s4.png")
        ],
        link: ['/item/pinksalt', '/item/mint', '/item/pinksalt', '/item/mint',],

        img_header: require("../assets/item_leather.jpg"),
        sound: require("../assets/mp3/leather.mp3"),
    },
}
export default
    items